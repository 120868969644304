// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let baseURL= 'https://test-api-nv.devbluedevs.com';
//let baseURL= 'https://api.emcontrolapp.com';
//let baseURL= 'https://dev-api.emcontrolapp.com';
//let baseURL= 'http://devapi.emcontrolapp.com:4000';
//let baseURL= 'http://localhost:3001';

export const environment = {
  production: false,
  loginURL: baseURL + '/users/login',
  checkTokenURL: baseURL + '/users/check',
  validateDoctorToken: baseURL + '/users/validateDoctorToken',
  registerDoctor: baseURL + '/users/registerDoctor',
  tokenID: 'session_token',
  userdata: 'session_user',
  userRole: 'session_role',
  expiresInID: 'token_expires_in',
  timezone: 'session_timezone',

  loggedUserChats: [],
  //Users
  getUsers: baseURL + '/users/getUsers',
  getUserByID: baseURL + '/users/getUserByID',
  getUserByUsername: baseURL + '/users/getUserByUsername',
  getRolePermitsURL: baseURL + '/users/getRolePermits',
  getUserPermitsURL: baseURL + '/users/getUserPermits',
  getUserByRoleURL: baseURL + '/users/getUserByRole',
  getUserByRoleCountryURL: baseURL + '/users/getUsersByRoleCountry',
  getAccessibleUsers: baseURL + '/users/getAccessibleUsers',

  getUsersPatients: baseURL + '/users/getUsersPatients',
  getUsersPatientsTotal: baseURL + '/users/getUsersPatientsTotal',

  createUser: baseURL + '/users/createUser',
  addDoctor: baseURL + '/users/doctors/add',
  changePassword: baseURL + '/users/password/change',
  forgotPassword: baseURL + '/users/password/forgot',
  tempPassword: baseURL + '/users/password/generateTemp/',

  updateUser: baseURL + '/users/updateUser/',
  deleteUser: baseURL + '/users/deleteUser/',

  //Screen Time
  getScreenTime: baseURL + '/users/getScreentime',
  getCountryScreenTime: baseURL + '/users/getCountryScreenTime',

  //Country
  getCountries: baseURL + '/countries/getCountries',
  createCountry: baseURL + '/countries/createCountry',

  //Events
  createEvent: baseURL + '/events/createEvent',
  getEvents: baseURL + '/events/getEvents',
  deleteEvent: baseURL + '/events/deleteEvent/',
  updateEvent: baseURL + '/events/updateEvent/',

  //Global Events
  createGlobalEvent: baseURL + '/events/createGlobalEvent',
  getGlobalEvents: baseURL + '/events/getGlobalEvents',
  deleteGlobalEvent: baseURL + '/events/deleteGlobalEvent/',
  updateGlobalEvent: baseURL + '/events/updateGlobalEvent/',

  //Roles
  getRoles: baseURL + '/users/getRoles',
  createRole: baseURL + '/users/createRole',

  updateRolePermits: baseURL + '/users/updateRolePermits',
  updateTimezone: baseURL + '/users/updateTimeZone',

  //Notification
  createNotification: baseURL + '/notifications/createNotification',

  //Education Section
    //Images for capsules and pathologies
    postImages : baseURL + '/documents/images/saveImage',

    getMedicalCapsules: baseURL + '/medical-capsules/',
    //Capsules
    getCapsules: baseURL + '/capsules/getCapsules',
    getCapsuleByID: baseURL + '/capsules/getCapsuleByID',
    createCapsules : baseURL + '/capsules/createCapsule',

    updateCapsule : baseURL + '/capsules/updateCapsule/',
    deleteCapsule : baseURL + '/capsules/deleteCapsule/',
    getEnabledCapsules: baseURL + '/capsules/getEnabledCapsules',
    uploadCapsuleImage: baseURL + '/capsules/uploadFile',

    //Pathologies
    getPathologies: baseURL + '/pathologies/getPathologies',
    getPathologyByID: baseURL + '/pathologies/getPathologyByID',
    createPathologies : baseURL + '/pathologies/createPathology',

    updatePathology : baseURL + '/pathologies/updatePathology/',
    deletePathology : baseURL + '/pathologies/deletePathology/',

    uploadPathologyImage: baseURL + '/pathologies/uploadFile',

    //Games Section
    getGames: baseURL + '/games/getGames',
    getGameByID: baseURL + '/games/getGameByID',
    createGames : baseURL + '/games/createGame',

    updateGame : baseURL + '/games/updateGame/',
    deleteGame : baseURL + '/games/deleteGame/',

    //FAQ Section
    getQuestions: baseURL + '/questions/',
    getQuestionByID: baseURL + '/questions/getQuestionByID',
    createQuestions : baseURL + '/questions/createQuestion',

    updateQuestion : baseURL + '/questions/updateQuestion/',
    deleteQuestion : baseURL + '/questions/deleteQuestion/',

  //Patient Services
    //Onboarding
    getUserProgress: baseURL + '/userProgress/',
    getUsersProgressByNurseID: baseURL + '/userProgress/getUsersProgressByNurse',
    getUsersProgressByID: baseURL + '/userProgress/getUsersProgressByID/',
    createUserProgress: baseURL + '/userProgress/createUserProgress',

    updateUsersProgress: baseURL + '/userProgress/updateUserProgress/',
    deleteUsersProgress: baseURL + '/userProgress/deleteUserProgress/',

    //Journal
    getJournalsUsers: baseURL + '/journal/getUsersJournals/',
    getAllJournalsFromUser: baseURL + '/journal/getAllFromUser/',
    getJournal: baseURL + '/journal/getJournalById/',


    //Treatments
    getTreatments: baseURL + '/treatments/',
    getTreatmentByID: baseURL + '/treatments/getTreatmentByID',

    createTreatment: baseURL + '/treatments/createTreatment',

    updateTreatment: baseURL + '/treatments/updateTreatment/',
    deleteTreatment: baseURL + '/treatments/deleteTreatment/',

    //Chat
    getUsersChatsByNurse: baseURL + '/chats/getChatsByNurse',
    getUserChat: baseURL + '/chats/getChat/',

    sendChatMessage: baseURL + '/messages/send',
    sendChatNotification: baseURL + '/messages/notification/send',
    readMessages: baseURL + '/messages/updateMessages/',
    encryptMessage: baseURL + '/messages/encrypt',
    decryptMessage: baseURL + '/messages/decrypt',
    decryptMessages: baseURL + '/messages/decryptMultiple',

    downloadChat: baseURL + '/chats/downloadChats',

  //General Service
    //Events
    getUniversityEvents: baseURL + '/university/',
    getUniversityEventByID: baseURL + '/university/getUniversityEventById',

    createUniversityEvents: baseURL + '/university/createUniversityEvents',

    updateUniversityEvents: baseURL + '/university/updateUniversityEvents/',
    deleteUniversityEvents: baseURL + '/university/deleteUniversityEvents/',

    //General Vars
    getAppVars: baseURL + '/appVars/',

    updateAppVars: baseURL + '/appVars/updateAppVars/',


  // Reports
    // General

    // Patients
    getActivePatients: baseURL + '/reports/getActives',
    getActivePatientsByCountry: baseURL + '/reports/getActiveUsersByCountry',
    getActiveUsersByMonth: baseURL + '/reports/getActiveUsersByMonth/',
    getUsersInApp: baseURL + '/reports/getUsersInApp/',

    getActivityStatus: baseURL + '/reports/getActivityStatus/',
    getActivityStatusByUser: baseURL + '/reports/getActivityStatusByUser/',

    getCapsulesAdvance: baseURL + '/reports/getCapsulesAdvance/',
    getCapsulesAdvanceByUser: baseURL + '/reports/getCapsulesAdvanceByUser/',

    getTestAdvance: baseURL + '/reports/getTestAdvance/',
    getTestAdvanceByUser: baseURL + '/reports/getTestAdvanceByUser/',

    // Doctors
    getActiveDoctors: baseURL + '/reports/getActiveDoctors',
    getActiveDoctorsByCountry: baseURL + '/reports/getActiveDoctorsByCountry',
    getActiveDoctorsByMonth: baseURL + '/reports/getActiveDoctorsByMonth/',
    getDoctorsInApp: baseURL + '/reports/getDoctorsInApp/',
    getDoctorsPatients: baseURL + '/reports/getDoctorsPatients/',

    getActivityStatusDoctors: baseURL + '/reports/getActivityStatusDoctors/',

    // Analytics
    getTotalUsers: baseURL + '/analytics/getTotalUsers/',

    getEventsByTime: baseURL + '/analytics/getEventsByTime/',
    getEventsByModuleVisits: baseURL + '/analytics/getEventsByModuleVisits/',
    getEventsHeatMap: baseURL + '/analytics/getEventsHeatMap/',

    getEventsByTimeComparative: baseURL + '/analytics/getEventsByTimeComparative/',
    getEventsByModuleVisitsComparative: baseURL + '/analytics/getEventsByModuleVisitsComparative/',

    // Tests Results
    getTestTypes            : baseURL + '/tests/getTestTypes',
    getUserTestRecords      : baseURL + '/tests/',
    getTests                : baseURL + '/tests/getTests',
    getTestsParameters      : baseURL + '/tests/parameters/',
    getTestsHistorical      : baseURL + '/tests/historical/',
    getJournalsHistorical   : baseURL + '/tests/journals/',
    getPatientLatestInfo    : baseURL + '/tests/latest/',
    getTestByID             : baseURL + '/tests/getTestByID/',

    getPdssTestFromGroup     : baseURL + '/tests/group/pdss',
    getSkillsTestFromGroup   : baseURL + '/tests/group/skills',
    getCognitionTestFromGroup: baseURL + '/tests/group/cognition',
    getWalkTestFromGroup     : baseURL + '/tests/group/walk',
    getFatigueTestFromGroup  : baseURL + '/tests/group/fatigue',

    updateUserActualPDSS     : baseURL + '/tests/updateUserActualPDSS/',
  //General variables
  // bucketAuth: 'AWS4-HMAC-SHA256 Credential=00cce5db13a97300a080/20210717/s3-de-central/s3/aws4_request, SignedHeaders=host;x-amz-content-sha256;x-amz-date, Signature=ce61c98015089ba90057cf15e14b877a2bb7ce9c0940d08150d3e50a2f170114',
  firebaseConfig : {
    apiKey: "AIzaSyAL91tj2PqU931PsqXcp_7QgXHgom4C2zQ",
    authDomain: "novartis-app-2332a.firebaseapp.com",
    projectId: "novartis-app-2332a",
    storageBucket: "novartis-app-2332a.appspot.com",
    messagingSenderId: "777099797835",
    appId: "1:777099797835:web:b2315e0d1e93da151e74ce",
    measurementId: "G-QZ523KHKYE"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
